import React from 'react'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Box from '@mui/material/Box'
import {TableBody, Typography} from '@mui/material'


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) {
            return order
        }
        return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
}

const CustomTableBody = ({rows, page, rowsPerPage, order, orderBy}) => {

    return (
        <>
            <TableBody>
                {rows.length > 0 ?
                    stableSort(rows, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {

                            return (
                                <TableRow
                                    hover
                                    tabIndex={-1}
                                    key={row.C1 + index + row.C2+row.C3}
                                >
                                    <TableCell align="center">{row.C1}</TableCell>
                                    <TableCell align="center">{row.C2}</TableCell>
                                    <TableCell align="center">{row.C3}</TableCell>
                                    <TableCell align="center">{row.C4}</TableCell>
                                    <TableCell align="center">{row.C5}</TableCell>
                                    <TableCell align="center">{row.C6}</TableCell>
                                    <TableCell align="center">{row.C7}</TableCell>
                                    <TableCell align="center">{row.C8}</TableCell>
                                    <TableCell align="center">{row.C9}</TableCell>
                                    <TableCell align="center">{row.C10}</TableCell>
                                    <TableCell align="center">{row.C11}</TableCell>
                                    <TableCell align="center">{row.C12}</TableCell>
                                </TableRow>
                            )
                        })
                    :
                    <TableRow
                        hover
                        tabIndex={-1}
                    >
                        <TableCell align="center" colSpan={12}>
                            <Typography sx={{margin: `auto`}} variant={'h5'}>Nothing Found</Typography>
                        </TableCell>
                    </TableRow>

                }
            </TableBody>
        </>
    )
}

export default CustomTableBody
