import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import TableSearch from './TableSearch';
import { CustomTableHead } from './TableHead';
import CustomTableBody from './TableBody';
import { debounce } from 'lodash';
import {useOutputs} from '../../helpers/useOutputs'

let data = []
export default function EnhancedTable() {
    const [rows, setRows] = useState([]);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('c1');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(30);

    useOutputs((response) => {
        data = response
        setRows(response)
    })

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const requestSearch = useCallback(
        debounce(value => {
            setRows(data.filter((row) => row.C4.toLowerCase().includes(value.toLowerCase())))
        }, 500),
        []
    );

    useEffect(() => {
        const maxPage = Math.ceil(rows.length / rowsPerPage) - 1
        if (maxPage < page && maxPage > -1) {
            console.log('setMaxPage', maxPage)
            setPage(maxPage)
        }
    }, [rows])

    return (
        <Box sx={ { width: 'auto', p: 5 } }>
            <Paper sx={ { width: '100%', mb: 2, pt: 2, bgcolor: `#ffffff` } }>
                <TableSearch rows={ rows } handleChangePage={ handleChangePage } page={ page }
                             handleChangeRowsPerPage={ handleChangeRowsPerPage } rowsPerPage={ rowsPerPage }
                             requestSearch={ requestSearch }/>

                <TableContainer>
                    <Table
                        sx={ { minWidth: 750 } }
                        aria-labelledby="tableTitle"
                        size={ 'medium' }
                    >
                        <CustomTableHead
                            order={ order }
                            orderBy={ orderBy }
                            onRequestSort={ handleRequestSort }
                            rowCount={ rows.length }
                        />
                        <CustomTableBody rowsPerPage={ rowsPerPage } rows={ rows } page={ page } order={ order }
                                         orderBy={ orderBy }/>
                    </Table>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={ [] }
                    component="div"
                    count={ rows.length }
                    rowsPerPage={ rowsPerPage }
                    labelRowsPerPage={ '' }
                    page={ page }
                    onPageChange={ handleChangePage }
                />

                {/*{rows.length > 5 && rowsPerPage === 5 ? <TablePagination*/}
                {/*    rowsPerPageOptions={[]}*/}
                {/*    component="div"*/}
                {/*    count={rows.length}*/}
                {/*    rowsPerPage={rowsPerPage}*/}
                {/*    labelRowsPerPage={''}*/}
                {/*    page={page}*/}
                {/*    onPageChange={handleChangePage}*/}
                {/*/> : null}*/}
                {/*{rows.length > 10 && rowsPerPage === 10 ? <TablePagination*/}
                {/*    rowsPerPageOptions={[]}*/}
                {/*    component="div"*/}
                {/*    count={rows.length}*/}
                {/*    rowsPerPage={rowsPerPage}*/}
                {/*    labelRowsPerPage={''}*/}
                {/*    page={page}*/}
                {/*    onPageChange={handleChangePage}*/}
                {/*/> : null}*/}
                {/*{rows.length > 25 && rowsPerPage === 25 ? <TablePagination*/}
                {/*    rowsPerPageOptions={[]}*/}
                {/*    component="div"*/}
                {/*    count={rows.length}*/}
                {/*    rowsPerPage={rowsPerPage}*/}
                {/*    labelRowsPerPage={''}*/}
                {/*    page={page}*/}
                {/*    onPageChange={handleChangePage}*/}
                {/*/> : null}*/}

            </Paper>
        </Box>
    );
}
