import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableSortLabel from '@mui/material/TableSortLabel'
import Box from '@mui/material/Box'
import {visuallyHidden} from '@mui/utils'
import PropTypes from 'prop-types'
import * as React from 'react'

const headCells = [

    {
        id: 'C1',
        numeric: false,
        label: 'Trade Label'
    },
    {
        id: 'C2',
        numeric: false,
        label: 'Time'
    },
    {
        id: 'C3',
        numeric: true,
        label: 'Trade Size'
    },
    {
        id: 'C4',
        numeric: false,
        label: 'Symbol'
    },
    {
        id: 'C5',
        numeric: false,
        label: 'Expiration Date'
    },
    {
        id: 'C6',
        numeric: true,
        label: 'Strike'
    },
    {
        id: 'C7',
        numeric: false,
        label: 'Call/Put'
    },
    {
        id: 'C8',
        numeric: true,
        label: 'Trade Price'
    },
    {
        id: 'C9',
        numeric: true,
        label: 'Trade Spreads'
    },
    {
        id: 'C10',
        numeric: true,
        label: 'Money'
    }
]

export function CustomTableHead(props) {
    const {order, orderBy, onRequestSort} =
        props
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property)
    }

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'center'}
                        padding={'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            <strong>{headCell.label}</strong>
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

CustomTableHead.propTypes = {
    //numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    //onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
}
