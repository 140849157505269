import React from 'react'
import {alpha, InputBase, styled} from '@mui/material'
import TablePagination from '@mui/material/TablePagination'
import Box from '@mui/material/Box'
import SearchIcon from '@mui/icons-material/Search'

const Search = styled('div')(({theme}) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto'
    }
}))

const SearchIconWrapper = styled('div')(({theme}) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}))

const StyledInputBase = styled(InputBase)(({theme}) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch'
        }
    }
}))

const MyComponent = ({rows, rowsPerPage, page, handleChangePage, handleChangeRowsPerPage, requestSearch}) => {
    return (
        <>
            <Box sx={{display: `flex`, alignItems: `center`, justifyContent: `space-between`}}>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={{'& .MuiTablePagination-displayedRows, .MuiTablePagination-actions': {display: 'none!important'}, fontFamily: `Rubik`, fontSize: `16px`}}
                />
                <Search sx={{display: `flex`, alignItems: `center`, ml: `auto`, justifyContent: `flex-end`}}>
                    Search:
                    <Box sx={{border: `1px solid grey`, display: `flex`, alignItems: `center`, ml: 2}}>
                        <SearchIconWrapper>
                            <SearchIcon/>
                        </SearchIconWrapper>
                        <StyledInputBase
                            onChange={(e) => requestSearch(e.target.value)}
                            placeholder="Search…"
                            inputProps={{'aria-label': 'search'}}
                        />
                    </Box>
                </Search>
            </Box>
        </>
    )
}

export default MyComponent
