import usa from '../img/usa.svg'
import shell from '../img/shell.svg'
import asana from '../img/asana.svg'
import apple from '../img/apple.svg'
import megawat from '../img/megawat.svg'
import mitsubishi from '../img/mitsubishi.svg'
import tesla from '../img/tesla.svg'

const outputRepository = require('../repositories/output.repository')

function createData (row) {
    let data = {}
    row.map((cell, index) => {
        data[`C${index + 1}`] = cell
    } )
    return data
}

const icons = {
    eHUSA: usa,
    eUNG: shell,
    eDXD: asana,
    eUGA: apple,
    eDIG: megawat,
    eDVN: mitsubishi,
    eENSV: tesla,
}

export default {
    getAllOutputs: (cb) => {
        outputRepository.fetchAllOutputs().then(
            result => {
                let data = []
                const getDateTimeProp = (prop) => {
                    return prop < 10 ? `0${prop}` : prop
                }
                result.forEach(function (item, index) {
                    const date = new Date(item.DateTime)

                    data.push(createData(
                        [
                            item.TradeLabel,
                            date ? `${getDateTimeProp(date.getHours())}:${getDateTimeProp(date.getMinutes())}:${getDateTimeProp(date.getSeconds())}` : ' - ',
                            item.Trade.Size,
                            item.Symbol,
                            '-',
                            item.Strike || '-',
                            '-',
                            item.Trade.Price,
                            item.TradeSpread,
                            item.Money || '-'
                        ]
                    ))
                })

                cb(data)
            },
            (error) => {
                console.error(error)
            }
        )
    }
}
