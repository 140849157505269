let instance = null
class WSConnection {
    connection
    handlers = []
    constructor () {
        this.connect()
    }
    static init () {
        if(instance) {
            return instance
        }
        instance = new this
        return instance
    }

    connect () {
        // this.connection = new WebSocket("ws://localhost:8999");
        this.connection = new WebSocket("wss://ws-awesome-trades.devserver.app:3002");
        this.connection.onopen = function() {
            console.log("Connected");
        };
        this.connection.onclose =this.onClose.bind(this)

        this.connection.onerror = this.onError.bind(this)

        this.connection.onmessage = this.onMessage.bind(this)
    }

    onError (error) {
        console.log("Error " + error.message);
    }

    onClose (event) {
        if (event.wasClean) {
            console.log('Connection is closed');
        } else {
            console.log('Connection is lost');
        }
        console.log('Code: ' + event.code + ' reason: ' + event.reason);

        setTimeout(() => {
            instance.connect()
        }, 1000);
    }

    onMessage (event) {
        try {
            this.handlers.forEach((handler) => handler(event))
        } catch (e) {
            console.error(e)
        }

    }


    addHandler (cb) {
        this.handlers.push(cb)
    }

    removeHandler (cb) {
        this.handlers = this.handlers.filter(h => ''+h !== ''+cb)
    }

    send (data) {
        if(!this.connection.readyState){
            setTimeout(function (){
                this.send(data);
            },100);
        } else {
            this.send(data);
        }
    }
}

export default WSConnection.init()
