import * as React from 'react'
import {useCallback, useEffect} from 'react'
import wsConnection from '../services/ws.service'
import outputService from '../services/output.service'

export const useOutputs = (setData) => {
    const [lastUpdate, setLastUpdated] = React.useState(Date.now.toString())

    const handler = event => {
        const message = JSON.parse(event.data)
        console.log(message)
        if (message.type === 'outputs-sync') {
            const date = Date.now().toString()
            setLastUpdated(date)
        }
    }

    useEffect(() => {
        outputService.getAllOutputs(data => setData(data))
    }, [lastUpdate]);

    useEffect(() => {
        const handler = event => {
            const message = JSON.parse(event.data)
            console.log(message)
            if (message.type === 'outputs-sync') {
                const date = Date.now().toString()
                setLastUpdated(date)
            }
        }
        console.log(wsConnection.addHandler)
        wsConnection.addHandler(handler)
        return () => wsConnection.removeHandler(handler)
    }, [])
}
